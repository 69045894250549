import React from "react";
import Select, { components } from "react-select";
import $ from "jquery";
import { calculatePrice as calculateBillableHoursPrice } from "../packs/billable_hours"
import { calculatePrice as calculateMaterialExpensesPrice } from "../packs/material_expenses"

const Option = props => {
  const article = props.data;
  const model = props.selectProps.model;

  if (model == "billable_hours") {
    return (
      <components.Option {...props}>
        <i className="far fa-regular fa-clock" />{" "} {article.description}
        <div>
          <small>
          <span title="Kaefer Code" className="mr-3">
            <i className="far fa-box-open" /> {article.kaefer_code || "–"}
          </span>
            <span title="iScala Nummer" className="mr-3">
            <i className="far fa-solid fa-hashtag" /> {article.iscala_number || "–"}
          </span>
            <span title="Aktueller Preis" className="mr-3">
            <i className="far fa-solid fa-money-bill" /> {article.default_price + " €" || "–"}
          </span>
          </small>
        </div>
      </components.Option>
    );
  } else if (model == "material_expenses") {
    return (
      <components.Option {...props}>
        <i className="far fa-ball-pile" />{" "} {article.description}
        <div>
          <small>
          <span title="Kaefer Code" className="mr-3">
            <i className="far fa-box-open" /> {article.kaefer_code || "–"}
          </span>
            <span title="iScala Nummer" className="mr-3">
            <i className="far fa-solid fa-hashtag" /> {article.iscala_number || "–"}
          </span>
            <span title="Aktueller Preis" className="mr-3">
            <i className="far fa-solid fa-money-bill" /> {article.default_price + " €" || "–"}
          </span>
          </small>
        </div>
      </components.Option>
    );
  };
};

const SingleValue = props => {
  const article = props.data;
  const model = props.selectProps.model;

  let icon = null;

  if (model == "billable_hours") {
    icon = "far fa-regular fa-clock";
  } else if (model == "material_expenses") {
    icon = "far fa-ball-pile";
  };

  return (
    <components.SingleValue {...props}>
      <i className={icon} />{" "} {article.description + " - " + article.default_price.replace(/\./g, ',') + " €"}
    </components.SingleValue>
  );
};

class PickerTemplate extends React.Component {
  state = {
    selectedArticle: this.props.articles.find(article => article.id === this.props.selectedArticleId)
  };

  handleChange = selectedArticle => {
    this.setState({ selectedArticle });


    if (this.props.model == "billable_hours") {
      $('#billable_hour_price').attr('selected_article_price', selectedArticle.default_price)
      calculateBillableHoursPrice();
    } else if (this.props.model == "material_expenses") {
      $('#material_expense_price').attr('selected_article_price', selectedArticle.default_price)
      calculateMaterialExpensesPrice();
    };
  };

  // Builds a searchable string for each article, used to compare options against the search input
  getOptionLabel = article => {
    if (!article) {
      return null;
    }

    return [
      article.description,
      article.kaefer_code,
      article.iscala_number,
      article.current_price
    ].join(" ");
  };

  // Sets the ArticleId is set on the HTML input for form submission
  getOptionValue = article => article.id;

  render() {
    const { articles } = this.props;
    const { selectedArticle } = this.state;
    const { model } = this.props;

    let selectId = null;
    let selectPlaceholderText = null;
    let selectInputId = null;
    let selectNoOptionsMessage = null;
    let labelHtmlFor = null;
    let labelContent = null;

    function modelSpecificStrings(){
      if (model == "billable_hours") {
        selectId = "hourPicker";
        selectPlaceholderText = "Tarif wählen…";
        selectInputId = "hour_picker";
        selectNoOptionsMessage = "Keinen Tarif gefunden.";
        labelHtmlFor = "hout_picker";
        labelContent = "Tarif";
      } else if (model == "material_expenses") {
        selectId = "materialPicker";
        selectPlaceholderText = "Material wählen…";
        selectInputId = "material_picker";
        selectNoOptionsMessage = "Keine Materialien gefunden.";
        labelHtmlFor = "material_picker";
        labelContent = "Material";
      };
    };

    modelSpecificStrings();

    if (selectedArticle) {
      if (model == "billable_hours") {
        $('#billable_hour_price').attr('selected_article_price', selectedArticle.default_price);
        $('#billable_hour_price').attr('amount_of_hours', $("#hour_amount_of_hours").val());
      } else if (model == "material_expenses") {
        $('#material_expense_price').attr('selected_article_price', selectedArticle.default_price);
        $('#material_expense_price').attr('amount_of_material', $("#amount_of_material").val());
      };
    };

    return (
      <>
        <label className="form-control-label select optional" htmlFor={labelHtmlFor}>
          {labelContent}
        </label>
        <Select
          isSearchable
          isClearable
          isDisabled={this.props.isDisabled}
          options={articles}
          name={this.props.htmlInputName}
          value={selectedArticle}
          id={selectId}
          model={model}
          getOptionLabel={this.getOptionLabel}
          getOptionValue={this.getOptionValue}
          onChange={this.handleChange}
          components={{ Option, SingleValue }}
          placeholder={selectPlaceholderText}
          noOptionsMessage={() => {selectNoOptionsMessage}}
          inputId={selectInputId}
          ref={select => (window.hourPickerSelect = select)}
        />
      </>
    );
  }
}

export default PickerTemplate;